<template>
  <div>
    <div
      class="d-flex flex-row align-items-center justify-content-between mb-8"
    >
      <h2 class="font-weight-bold my-2 mr-5">
        Taşımacı Firma Tanımla
      </h2>
      <div class="d-flex flex-row align-items-center">
        <router-link :to="{ name: 'transport-companies' }" v-slot="{ href }">
          <a
            :href="href"
            class="btn btn-light-primary btn-fixed-height font-weight-bold px-2 px-lg-5"
          >
            <span class="d-none d-md-inline">Taşımacı Firmalar</span>
          </a>
        </router-link>
      </div>
    </div>
    <TransportCompanyWizard wizard-type="CREATE" />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TransportCompanyWizard from "./components/TransportCompanyWizard.vue";

export default {
  name: "CreateTransportCompany",
  components: {
    TransportCompanyWizard
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "CreateTransportCompany" }]);
  }
};
</script>

<style></style>
